<template>
    <v-dialog
        :model-value="modelValue"
        @update:model-value="$emit('update:modelValue', $event)"
        persistent
        width="741px"
    >
        <v-card rounded="xl" class="pa-8">
            <v-card-title class="text-center exie-text-h5 mb-8">How do you feel about the interview experience?</v-card-title>
            <v-row class="justify-center ma-6">
                <v-col cols="12" sm="3" class="text-center">
                    <v-hover v-slot="{ isHovering, props }">
                        <v-card
                            v-bind="props"
                            rounded="xl"
                            elevation="0"
                            class="pa-4 cursor-pointer bg-grey-lighten-4"
                            @click="handleSelect('frustrated')"
                        >
                            <div class="text-center">
                                <v-img
                                    :src="FrustratedIcon"
                                    alt="Frustrated"
                                    class="feedback-icon mx-auto"
                                    width="64"
                                    height="64"
                                ></v-img>
                                <div class="mt-2">Frustrated</div>
                            </div>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="12" sm="3" class="text-center">
                    <v-hover v-slot="{ isHovering, props }">
                        <v-card
                            v-bind="props"
                            rounded="xl"
                            elevation="0"
                            class="pa-4 cursor-pointer bg-grey-lighten-4"
                            @click="handleSelect('neutral')"
                        >
                            <div class="text-center">
                                <v-img
                                    :src="NeutralIcon"
                                    alt="Neutral"
                                    class="feedback-icon mx-auto"
                                    width="64"
                                    height="64"
                                ></v-img>
                                <div class="mt-2">Neutral</div>
                            </div>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="12" sm="3" class="text-center">
                    <v-hover v-slot="{ isHovering, props }">
                        <v-card
                            v-bind="props"
                            rounded="xl"
                            elevation="0"
                            class="pa-4 cursor-pointer bg-grey-lighten-4"
                            @click="handleSelect('motivated')"
                        >
                            <div class="text-center">
                                <v-img
                                    :src="MotivatedIcon"
                                    alt="Motivated"
                                    class="feedback-icon mx-auto"
                                    width="64"
                                    height="64"
                                ></v-img>
                                <div class="mt-2">Motivated</div>
                            </div>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="12" sm="3" class="text-center">
                    <v-hover v-slot="{ isHovering, props }">
                        <v-card
                            v-bind="props"
                            rounded="xl"
                            elevation="0"
                            class="pa-4 cursor-pointer bg-grey-lighten-4"
                            @click="handleSelect('excited')"
                        >
                            <div class="text-center">
                                <v-img
                                    :src="ExcitedIcon"
                                    alt="Excited"
                                    class="feedback-icon mx-auto"
                                    width="64"
                                    height="64"
                                ></v-img>
                                <div class="mt-2">Excited</div>
                            </div>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref } from 'vue'
import FrustratedIcon from '@/assets/interview/feedback/Frustrated.svg'
import NeutralIcon from '@/assets/interview/feedback/Neutral.svg'
import MotivatedIcon from '@/assets/interview/feedback/Motivated.svg'
import ExcitedIcon from '@/assets/interview/feedback/Excited.svg'

const props = defineProps({
    modelValue: Boolean
})

const emit = defineEmits(['update:modelValue', 'submit'])
const selected = ref(null)

const handleSelect = (value) => {
    selected.value = value
    emit('submit', value)
    emit('update:modelValue', false)
    selected.value = null
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}

.feedback-icon {
    max-width: 64px;
    max-height: 64px;
}
</style> 