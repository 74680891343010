<template>
    <v-row class="mt-5 mb-5">
        <v-col cols="12" md="8">
            <p class="exie-text-body-1">
                The following grade descriptions represent general expectations of big tech companies like Google, Amazon, Meta, Apple, Netflix, and others. Each company is unique and may have its own grading criteria for designers. However, we focus on evaluating the key qualities that reflect your level of expertise and maturity.
            </p>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="3">
            <p :class="{'exie-text-subtitle-1': mdAndUp, 'font-weight-bold': mdAndUp, 'exie-text-h5': !mdAndUp}">
                Responsibility
            </p>
            <p class="mt-3 exie-text-body-2">
                Assessing the ability to take on tasks and see them through to successful completion
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold">
                Junior
            </p>
            <p class="mt-3 exie-text-body-2">
                Executes assigned tasks under close supervision, contributing to small parts of a project
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold">
                Middle
            </p>
            <p class="mt-3 exie-text-body-2">
                Independently manages small-to-medium projects, making decisions within their scope
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold">
                Senior
            </p>
            <p class="mt-3 exie-text-body-2">
                Drives complex initiatives, taking strategic decisions that shape product direction
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold">
                Lead
            </p>
            <p class="mt-3 exie-text-body-2">
                Defines the overall design strategy, manages teams, and ensures that design efforts align with business goals
            </p>
        </v-col>
    </v-row>
    <v-divider class="my-3" />
    <v-row>
        <v-col cols="12" md="3">
            <p :class="{'exie-text-subtitle-1': mdAndUp, 'font-weight-bold': mdAndUp, 'exie-text-h5': !mdAndUp}">
                Impact
            </p>
            <p class="mt-3 exie-text-body-2">
                The ability to influence the product, team, and overall business
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold" :style="{minHeight: '28px'}">
                <span :class="{'d-none': mdAndUp}">Junior</span>
            </p>
            <p class="mt-3 exie-text-body-2">
                Contributes to improving individual product elements
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold" :style="{minHeight: '28px'}">
                <span :class="{'d-none': mdAndUp}">Middle</span>
            </p>
            <p class="mt-3 exie-text-body-2">
                Enhances user experience and product metrics
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold" :style="{minHeight: '28px'}">
                <span :class="{'d-none': mdAndUp}">Senior</span>
            </p>
            <p class="mt-3 exie-text-body-2">
                Influences product strategy, improves key business indicators
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold" :style="{minHeight: '28px'}">
                <span :class="{'d-none': mdAndUp}">Lead</span>
            </p>
            <p class="mt-3 exie-text-body-2">
                Sets product development direction, impacts the company's business strategy
            </p>
        </v-col>
    </v-row>
    <v-divider class="my-3" />
    <v-row>
        <v-col cols="12" md="3">
            <p :class="{'exie-text-subtitle-1': mdAndUp, 'font-weight-bold': mdAndUp, 'exie-text-h5': !mdAndUp}">
                Problem Finding
            </p>
            <p class="mt-3 exie-text-body-2">
                The ability to identify and articulate problems that require solutions
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold" :style="{minHeight: '28px'}">
                <span :class="{'d-none': mdAndUp}">Junior</span>
            </p>
            <p class="mt-3 exie-text-body-2">
                Recognizes obvious design inconsistencies and usability issues
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold" :style="{minHeight: '28px'}">
                <span :class="{'d-none': mdAndUp}">Middle</span>
            </p>
            <p class="mt-3 exie-text-body-2">
                Uses research and analysis to uncover hidden or less apparent problems
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold" :style="{minHeight: '28px'}">
                <span :class="{'d-none': mdAndUp}">Senior</span>
            </p>
            <p class="mt-3 exie-text-body-2">
                Identifies systemic challenges affecting both the product and business performance
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold" :style="{minHeight: '28px'}">
                <span :class="{'d-none': mdAndUp}">Lead</span>
            </p>
            <p class="mt-3 exie-text-body-2">
                Anticipates future challenges and proactively develops strategies to mitigate them
            </p>
        </v-col>
    </v-row>
    <v-divider class="my-3" />
    <v-row>
        <v-col cols="12" md="3">
            <p :class="{'exie-text-subtitle-1': mdAndUp, 'font-weight-bold': mdAndUp, 'exie-text-h5': !mdAndUp}">
                Problem Solving
            </p>
            <p class="mt-3 exie-text-body-2">
                The ability to effectively address identified problems
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold" :style="{minHeight: '28px'}">
                <span :class="{'d-none': mdAndUp}">Junior</span>
            </p>
            <p class="mt-3 exie-text-body-2">
                Proposes solutions for simple tasks under mentor guidance
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold" :style="{minHeight: '28px'}">
                <span :class="{'d-none': mdAndUp}">Middle</span>
            </p>
            <p class="mt-3 exie-text-body-2">
                Develops well-structured approaches to address more complex user needs
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold" :style="{minHeight: '28px'}">
                <span :class="{'d-none': mdAndUp}">Senior</span>
            </p>
            <p class="mt-3 exie-text-body-2">
                Implements innovative solutions that are both scalable and impactful
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold" :style="{minHeight: '28px'}">
                <span :class="{'d-none': mdAndUp}">Lead</span>
            </p>
            <p class="mt-3 exie-text-body-2">
                Develops strategic solutions that transform the product and business
            </p>
        </v-col>
    </v-row><v-divider class="my-3" />
    <v-row>
        <v-col cols="12" md="3">
            <p :class="{'exie-text-subtitle-1': mdAndUp, 'font-weight-bold': mdAndUp, 'exie-text-h5': !mdAndUp}">
                Leadership
            </p>
            <p class="mt-3 exie-text-body-2">
                The ability to set a vision, mentor teams, collaborate across functions, and drive innovation to create impactful products
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold" :style="{minHeight: '28px'}">
                <span :class="{'d-none': mdAndUp}">Junior</span>
            </p>
            <p class="mt-3 exie-text-body-2">
                Actively seeks feedback and focuses on personal growth as a team member
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold" :style="{minHeight: '28px'}">
                <span :class="{'d-none': mdAndUp}">Middle</span>
            </p>
            <p class="mt-3 exie-text-body-2">
                Mentors junior colleagues, contributes to team success, and demonstrates leadership in projects
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold" :style="{minHeight: '28px'}">
                <span :class="{'d-none': mdAndUp}">Senior</span>
            </p>
            <p class="mt-3 exie-text-body-2">
                Leads key projects, influences cross-functional decisions, and drives design excellence
            </p>
        </v-col>
        <v-col cols="12" md="2">
            <p class="exie-text-subtitle-1 font-weight-bold" :style="{minHeight: '28px'}">
                <span :class="{'d-none': mdAndUp}">Lead</span>
            </p>
            <p class="mt-3 exie-text-body-2">
                Cultivates a design-driven culture across the organization, makes strategic decisions, and effectively manages and inspires design teams
            </p>
        </v-col>
    </v-row>
</template>

<script>
import { useDisplay } from 'vuetify'
export default {
    setup() {
        const {mdAndUp} = useDisplay()
        return {
            mdAndUp
        }
    }
}
</script>