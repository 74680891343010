<template>
    <div>
        <v-dialog
            :model-value="modelValue"
            @update:model-value="handleClose"
            persistent
            width="741px"
        >
            <v-card rounded="xl" v-if="interview.type === 'design_screening'">
                <v-card-title><v-btn :icon="mdiClose" color="black" class="float-end" density="compact" @click="handleDashboardClick"></v-btn></v-card-title>
                <div class="d-flex flex-column align-center justify-center pb-8 px-3">
                    <p class="exie-text-h5">{{ getFinalTitle() }}</p>
                    <p class="exie-text-h5">Your score is {{ interview.score ?? 0 }}/10 of the <span class="text-capitalize">{{ interview.score_grade }}</span> designer level</p>
                    <p class="mt-5 exie-text-body-1 text-medium-emphasis text-center" :style="{maxWidth: '563px'}">This is the approximate rating that shows how your responses align with the hiring manager's expectations for this role. You can review your answers and results in the History section.</p>
                    <p class="mt-5 exie-text-body-1 text-medium-emphasis text-center" :style="{maxWidth: '563px'}">Have any feedback about your interview experience or suggestions — feel free to take a quick survey. Also, you can join our community on <a href="https://t.me/+MRmcwZNJgXk0MWNi" target="_blank" class="text-decoration-none">Telegram!</a></p>
                    <v-btn class="mt-8 rounded-16" color="black" variant="flat" size="large" @click="$emit('take-survey')">Provide Feedback</v-btn>
                    <v-btn class="mt-3 rounded-16" variant="text" size="large" @click="handleReviewResults">Review Results</v-btn>
                </div>
            </v-card>
            <v-card rounded="xl" v-if="interview.type === 'design_screening_base'">
                <v-card-title><v-btn :icon="mdiClose" color="black" class="float-end" density="compact" @click="handleDashboardClick"></v-btn></v-card-title>
                <div class="d-flex flex-column align-center justify-center pb-8 px-3">
                    <p class="exie-text-h5">{{ getFinalTitle() }}</p>
                    <p class="exie-text-h5">Your score is {{ interview.score ?? 0 }}/10 of the <span class="text-capitalize">{{ interview.score_grade }}</span> designer level</p>
                    <p class="mt-5 exie-text-body-1 text-medium-emphasis text-center" :style="{maxWidth: '563px'}">This is the approximate rating that shows how your responses align with the hiring manager's expectations for this role. You can review your answers and results in the History section.</p>
                    <p class="mt-5 exie-text-body-1 text-medium-emphasis text-center" :style="{maxWidth: '563px'}">Have any feedback about your interview experience or suggestions — feel free to take a quick survey. Also, you can join our community on <a href="https://t.me/+MRmcwZNJgXk0MWNi" target="_blank" class="text-decoration-none">Telegram!</a></p>
                    <v-btn class="mt-8 rounded-16" color="black" variant="flat" size="large" @click="$emit('take-survey')">Provide Feedback</v-btn>
                    <v-btn class="mt-3 rounded-16" variant="text" size="large" @click="handleReviewResults">Review Results</v-btn>
                </div>
            </v-card>
            <v-card rounded="xl" v-if="interview.type === 'design_self_assessment'">
                <v-card-title><v-btn :icon="mdiClose" color="black" class="float-end" density="compact" @click="handleDashboardClick"></v-btn></v-card-title>
                <div class="d-flex flex-column align-center justify-center pb-8 px-3">
                    <p class="exie-text-h4">Congratulations!</p>
                    <p class="exie-text-h4">Your Grade: <span class="text-capitalize">{{ interview.score_grade }}</span></p>
                    <div class="d-flex flex-wrap justify-center align-center mt-5" :style="{maxWidth: '563px'}">
                        <div :style="{minWidth: '120px'}" class="my-5 mx-5">
                            <p class="exie-text-subtitle-1 font-weight-bold text-center">Impact</p>
                            <p>
                                <v-icon class="mr-1" :color="interview.score_data?.impact_score >= 1 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                                <v-icon class="mr-1" :color="interview.score_data?.impact_score >= 2 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                                <v-icon class="mr-1" :color="interview.score_data?.impact_score >= 3 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                                <v-icon class="mr-1" :color="interview.score_data?.impact_score >= 4 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                            </p>
                        </div>
                        <div :style="{minWidth: '120px'}" class="my-5 mx-5">
                            <p class="exie-text-subtitle-1 font-weight-bold text-center">Responsibility</p>
                            <p>
                                <v-icon class="mr-1" :color="interview.score_data?.responsibility_score >= 1 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                                <v-icon class="mr-1" :color="interview.score_data?.responsibility_score >= 2 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                                <v-icon class="mr-1" :color="interview.score_data?.responsibility_score >= 3 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                                <v-icon class="mr-1" :color="interview.score_data?.responsibility_score >= 4 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                            </p>
                        </div>
                        <div :style="{minWidth: '120px'}" class="my-5 mx-5">
                            <p class="exie-text-subtitle-1 font-weight-bold text-center">Problem Finding</p>
                            <p>
                                <v-icon class="mr-1" :color="interview.score_data?.problem_finding_score >= 1 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                                <v-icon class="mr-1" :color="interview.score_data?.problem_finding_score >= 2 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                                <v-icon class="mr-1" :color="interview.score_data?.problem_finding_score >= 3 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                                <v-icon class="mr-1" :color="interview.score_data?.problem_finding_score >= 4 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                            </p>
                        </div>
                        <div :style="{minWidth: '120px'}" class="my-5 mx-5">
                            <p class="exie-text-subtitle-1 font-weight-bold text-center">Problem Solving</p>
                            <p>
                                <v-icon class="mr-1" :color="interview.score_data?.problem_solving_score >= 1 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                                <v-icon class="mr-1" :color="interview.score_data?.problem_solving_score >= 2 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                                <v-icon class="mr-1" :color="interview.score_data?.problem_solving_score >= 3 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                                <v-icon class="mr-1" :color="interview.score_data?.problem_solving_score >= 4 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                            </p>
                        </div>
                        <div :style="{minWidth: '120px'}" class="my-5 mx-5">
                            <p class="exie-text-subtitle-1 font-weight-bold text-center">Leadership</p>
                            <p>
                                <v-icon class="mr-1" :color="interview.score_data?.leadership_score >= 1 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                                <v-icon class="mr-1" :color="interview.score_data?.leadership_score >= 2 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                                <v-icon class="mr-1" :color="interview.score_data?.leadership_score >= 3 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                                <v-icon class="mr-1" :color="interview.score_data?.leadership_score >= 4 ? 'black': 'grey-lighten-1'" :icon="mdiStar" />
                            </p>
                        </div>
                    </div>
                    <p class="mt-5 exie-text-body-1 text-medium-emphasis text-center" :style="{maxWidth: '563px'}">This approximate rating indicates how well your responses align with the hiring manager's expectations for this role. You can review your answers and results in the History Section.</p>
                    <p class="mt-5 exie-text-body-1 text-medium-emphasis text-center" :style="{maxWidth: '563px'}">Have any feedback or suggestions — fell free to take a <a href="https://forms.gle/by5YK8DphvR5x7ZbA" target="_blank" class="exie-text-body-1 text-decoration-none text-primary">Quick Survey</a>. Also, you can join to our community on <a href="https://t.me/+MRmcwZNJgXk0MWNi" target="_blank" class="exie-text-body-1 text-decoration-none text-primary">Telegram!</a></p>
                    <v-btn class="mt-8 rounded-16" color="black" variant="flat" size="large" @click="handleReviewResults">Review the Feedback</v-btn>
                    <v-btn class="mt-3 rounded-16" variant="text" size="large" @click="handleDashboardClick">Back To Dashboard</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <InterviewFeedback
            v-model="showFeedback"
            @submit="handleFeedbackSubmit"
        />
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { mdiClose, mdiStar } from '@mdi/js'
import InterviewFeedback from './InterviewFeedback.vue'

const props = defineProps({
    modelValue: Boolean,
    interview: Object
})

const emit = defineEmits(['update:modelValue', 'take-survey'])

const router = useRouter()
const showFeedback = ref(false)
const shouldNavigateToDashboard = ref(false)

const handleClose = (value) => {
    if (!value) {
        showFeedback.value = true
    }
    emit('update:modelValue', value)
}

const handleDashboardClick = () => {
    shouldNavigateToDashboard.value = true
    showFeedback.value = true
    emit('update:modelValue', false)
}

const handleReviewResults = () => {
    showFeedback.value = true
    emit('update:modelValue', false)
}

const handleFeedbackSubmit = async (feedback) => {
    try {
        await fetch(`/api/interview/feedback/${props.interview.id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                feedback: feedback
            })
        })
        if (shouldNavigateToDashboard.value) {
            router.push({ name: 'dashboard' })
        }
    } catch (error) {
        console.error('Failed to submit feedback:', error)
        if (shouldNavigateToDashboard.value) {
            router.push({ name: 'dashboard' })
        }
    }
}

const getFinalTitle = () => {
    if (props.interview.score <= 3) {
        return 'Give it another shot!'
    } else if (props.interview.score <= 6) {
        return 'Well done!'
    } else {
        return 'Congratulations!'
    }
}
</script> 