<template>
    <div class="mb-3"></div>
    <v-row justify="center">
        <v-col cols="12" sm="8" md="9" class="pa-2">
            <p class="exie-text-h4">Hello, {{ user?.name.split(' ')[0] }}!</p>
            <p class="exie-text-h4">You have <span class="text-deep-orange-lighten-2">{{ user?.available_interviews }} available</span> interviews</p>
        </v-col>
        <v-col cols="12" sm="4" md="3" class="pa-2">
            <v-btn @click="handleAddInterview" class="float-right rounded-16" color="black" variant="outlined" size="large" v-if="user?.available_interviews == 0">Need More Interview?</v-btn>
        </v-col>
    </v-row>
    <v-row class="mt-10" v-if="activeInterviews.length > 0">
        <v-col cols="12" class="pa-2 pb-0">
            <p class="exie-text-subtitle-1 font-weight-bold">Active Interviews</p>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-for="(interview, index) in activeInterviews" :key="index" class="pa-2">
            <v-card class="px-5 py-7 rounded-16 h-100 bg-grey-lighten-4 d-flex flex-column" elevation="0">
                <p class="exie-text-h5">{{ getInterviewTitle(interview.type) }}</p>
                <p class="text-caption text-medium-emphasis mt-1 mb-7">{{ interview.language ? getLanguageTitle(interview.language): '' }} | {{ getFullDateFromString(interview.ts_created) }}</p>
                <p class="mt-auto float-end"><v-btn variant="flat" color="black" size="large" class="rounded-16" :to="{name: 'interview', params: {id: interview.id}}">Continue</v-btn></p>
            </v-card>
        </v-col>
    </v-row>
    <v-row class="mt-10">
        <v-col cols="12" class="pa-2 pb-0">
            <p class="exie-text-subtitle-1 font-weight-bold">Choose an interview type</p>
        </v-col>
        <v-col cols="12" md="6" class="pa-2">
            <v-card rounded="xl" class="d-flex flex-column px-7 py-7 h-100 bg-grey-lighten-4" elevation="0">
                <p class="exie-text-h5">Screening</p>
                <p class="exie-text-body-1 mt-3">A screening interview is the first step in the hiring process, aimed at determining whether a candidate has the skills, experience, and mindset required for the role.</p>
                <p class="mt-auto">
                    <v-btn
                        color="black"
                        flat
                        size="large"
                        class="rounded-16 mt-5"
                        @click="handleCreateInterview('design_screening')"
                        :disabled="user?.available_interviews == 0"
                    >
                        Start
                    </v-btn>
                </p>
            </v-card>
        </v-col>
        <v-col v-if="user.features['feature_screening_grades']" cols="12" md="6" class="pa-2">
            <v-card rounded="xl" class="d-flex flex-column px-7 py-7 h-100 bg-grey-lighten-4" elevation="0">
                <p class="exie-text-h5">Screening (Grades TEST)</p>
                <p class="exie-text-body-1 mt-3">A screening interview is the first step in the hiring process, aimed at determining whether a candidate has the skills, experience, and mindset required for the role.</p>
                <p class="mt-auto">
                    <v-btn
                        color="black"
                        flat
                        size="large"
                        class="rounded-16 mt-5"
                        @click="handleCreateInterview('design_screening_base')"
                        :disabled="user?.available_interviews == 0"
                    >
                        Start
                    </v-btn>
                </p>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" class="pa-2">
            <v-card rounded="xl" class="d-flex flex-column px-7 py-7 h-100 bg-grey-lighten-4" elevation="0">
                <p class="exie-text-h5">Self Assessment</p>
                <p class="exie-text-body-1 mt-3">A Self-Assessment Interview is a type of interview where a candidate evaluates their own skills, strengths, weaknesses, achievements, and areas for improvement</p>
                <p class="mt-auto">
                    <v-btn
                        color="black"
                        flat
                        size="large"
                        class="rounded-16 mt-5"
                        @click="handleCreateInterview('design_self_assessment')"
                        :disabled="user?.available_interviews == 0"
                    >
                        Start
                    </v-btn>
                </p>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" class="pa-2">
            <v-card rounded="xl" class="d-flex flex-column px-7 py-7 h-100 bg-grey-lighten-4" elevation="0">
                <p class="exie-text-h5">Manager Interview</p>
                <p class="exie-text-body-1 mt-3">It is a stage in the hiring process where the focus shifts from technical design skills to assessing the candidate’s ability to thrive in a team</p>
                <p class="mt-auto">
                    <v-btn
                        color="black"
                        flat
                        size="large"
                        class="rounded-16 mt-5"
                        variant="outlined"
                        @click="handleJoinWaitlist('design_manager_interview_waitlist')"
                        :disabled="user?.state?.design_manager_interview_waitlist == true"
                    >
                        {{ user?.state?.design_manager_interview_waitlist == true ? 'We\'ll Notify You' : 'Join Waitlist' }}
                    </v-btn>
                </p>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" class="pa-2">
            <v-card rounded="xl" class="d-flex flex-column px-7 py-7 h-100 bg-grey-lighten-4" elevation="0">
                <p class="exie-text-h5">Culture Fit</p>
                <p class="exie-text-body-1 mt-3">The cultural fit interview is the process of assessing possible applicants to understand their cultural alignment with the company</p>
                <p class="mt-auto">
                    <v-btn
                        color="black"
                        flat
                        size="large"
                        class="rounded-16 mt-5"
                        variant="outlined"
                        @click="handleJoinWaitlist('design_culter_fit_interview_waitlist')"
                        :disabled="user?.state?.design_culter_fit_interview_waitlist == true"
                    >
                        {{ user?.state?.design_culter_fit_interview_waitlist == true ? 'We\'ll Notify You' : 'Join Waitlist' }}
                    </v-btn>
                </p>
            </v-card>
        </v-col>
    </v-row>

    <v-snackbar
      v-model="waitlistNotification"
      timeout="3500"
      close-on-content-click
      location="top right"
      color="black"
    >
        You'll get a notification to your inbox once the feature is live
    </v-snackbar>
</template>

<script>
import { useAppStateStore } from '@/stores/AppStateStore'
import api from '@/utils/api'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { interviews, getInterviewTitle, languages, getLanguageTitle } from '@/utils/interviews'
import { ref } from 'vue'
import { getFullDateFromString } from '@/utils/dataFormatter'

export default {
    setup() {
        const router = useRouter()
        const appStateStore = useAppStateStore()

        const {user} = storeToRefs(appStateStore)

        const waitlistNotification = ref(false)

        appStateStore.loadUser()

        const createingInterview = ref(false)

        const language = ref(window.localStorage.getItem('interview_language') || 'english')

        const activeInterviews = ref([])

        const handleAddInterview = async () => {
            window.open('https://forms.gle/haxb4JU7fsFZB5wj6', '_blank')
        }

        const handleCreateInterview = async (type) => {
            if(createingInterview.value) {
                return
            }
            createingInterview.value = true
            const response = await api.post('/api/interview/create', {
                type: type
            })

            if(response.interview) {
                router.push({name: 'interview', params: {id: response.interview.id}})
            }

            createingInterview.value = false
        }

        const getActiveInterviews = async () => {
            const response = await api.get('/api/interview/list/active')

            if(response.interviews) {
                activeInterviews.value = response.interviews
            }
        }

        const handleJoinWaitlist = async (waitlist) => {
            waitlistNotification.value = false
            const response = await api.post('/api/init/state', {
                param: waitlist,
                value: true
            })

            if (response.user) {
                waitlistNotification.value = true
                appStateStore.updateUser(response.user)
            }
        }

        getActiveInterviews()

        return {user, interviews, waitlistNotification, handleCreateInterview, activeInterviews, getInterviewTitle, getFullDateFromString, getLanguageTitle, handleAddInterview, handleJoinWaitlist}
    }
}
</script>