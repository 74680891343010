<template>
    <v-row v-if="messages && !started" class="mt-5 mb-5" justify="center">
        <v-col cols="12" md="8">
            <div v-for="(message, index) in messages" :key="index" class="pa-5 ma-3 rounded-lg text-pre-line" 
            :class="{ 
                'bg-grey-lighten-3': message.role == 'user',
                'bg-deep-orange-lighten-5': message.role == 'assistant'
            }">
                <p class="exie-text-subtitle-1 font-weight-bold mb-2">{{ message.role == 'user' ? 'You' : 'Interviewer' }}</p>
                <div class="exie-text-body-1 text-black" v-html="formatMarkdown(message.content)"></div>
            </div>
        </v-col>
    </v-row>
</template>

<script setup>
defineProps({
    messages: {
        type: Array,
        required: true
    },
    started: {
        type: Boolean,
        required: true
    }
});

const formatMarkdown = (text) => {
    if (!text) return '';
    
    // Заменяем заголовки
    text = text.replace(/^# (.*$)/gm, '<h1 class="exie-text-h1">$1</h1>');
    text = text.replace(/^## (.*$)/gm, '<h2 class="exie-text-h2">$1</h2>');
    text = text.replace(/^### (.*$)/gm, '<h3 class="exie-text-h3">$1</h3>');
    
    // Заменяем жирный текст
    text = text.replace(/\*\*(.*?)\*\*/g, '<span class="font-weight-bold">$1</span>');
    text = text.replace(/__(.*?)__/g, '<span class="font-weight-bold">$1</span>');
    
    // Заменяем курсив
    text = text.replace(/\*(.*?)\*/g, '<span class="font-italic">$1</span>');
    text = text.replace(/_(.*?)_/g, '<span class="font-italic">$1</span>');
    
    // Заменяем код
    text = text.replace(/`(.*?)`/g, '<code>$1</code>');
    
    return text;
};
</script>

<style scoped>
h1, h2, h3 {
    margin: 1em 0 0.5em 0;
    line-height: 1.2;
}

h1 { font-size: 1.8em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.3em; }

ul, ol {
    margin: 0.5em 0;
    padding-left: 2em;
}

li {
    margin: 0.3em 0;
}

code {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-family: monospace;
}

strong {
    font-weight: bold;
}

em {
    font-style: italic;
}
</style> 