<template>
    <v-sheet v-if="interview">
        <v-sheet>
            <v-row>
                <v-col cols="12" sm="7" md="8">
                    <p class="exie-text-h4 mb-5">{{ getInterviewTitle(interview.type) }} Interview {{ interview.status == 2 ? '(Finished)' : '' }}</p>
                </v-col>
            </v-row>
            
            <v-tabs v-model="currentTab">
                <v-tab v-for="(tab, index) in getInterviewTabs(interview.type)" :key="index" :value="tab.id"> 
                    {{ tab.title }} 
                </v-tab>
            </v-tabs>

            <v-tabs-window v-model="currentTab">
                <v-tabs-window-item value="interview">
                    <v-row class="mb-5 mt-5 pb-0" v-if="!started && interview.status == 1">
                        <v-col cols="12" md="7">
                            <p class="exie-text-body-1 text-pre-line">{{ getInterviewDescription(interview.type) }}</p>
                        </v-col>
                    </v-row>
                    <v-row v-if="!started && interview.status == 1" class="mt-0">
                        <v-col cols="12" md="7" v-if="interview.messages.length == 0 && user.features['feature_interview_cv_based'] && interview.type != 'design_self_assessment'">
                            <div>
                                <p class="exie-text-subtitle-1 font-weight-bold">Resume Based Interview (Optional)</p>
                                <p class="exie-text-body-1 text-medium-emphasis mt-1">Take a personalized interview based on your resume</p>
                                <CVUploader class="mt-3" />
                            </div>
                        </v-col>
                        <v-col cols="12" md="7" v-if="interview.messages.length == 0 && user.features['feature_screening_grades'] && interview.type == 'design_screening_base'">
                            <div>
                                <p class="exie-text-subtitle-1 font-weight-bold">Choose grade</p>
                                <v-select v-model="subtype" :items="getSubtypes(interview.type)" variant="solo-filled" flat rounded="lg" hide-details class="mt-3" color="black"></v-select>
                            </div>
                        </v-col>
                        <v-col cols="12" md="7" v-if="interview.messages.length == 0">
                            <div>
                                <p class="exie-text-subtitle-1 font-weight-bold">Interview Language</p>
                                <v-select v-model="language" :items="languages" variant="solo-filled" flat rounded="lg" hide-details class="mt-3" color="black" @update:model-value="handleUpdateLanguage"></v-select>
                                <p class="text-description text-medium-emphasis mt-3 mb-5">Take a deep breath and let's start improving!</p>
                                <v-btn class="rounded-16 mt-3" size="large" varinat="flat" color="black" @click="handleStartInterview">Start Interview</v-btn>
                            </div>
                        </v-col>
                        <v-col cols="12" v-if="interview.messages.length > 0">
                            <v-btn class="rounded-16" variant="flat" size="large" color="black" @click="handleContinueInterview">Continue Interview</v-btn>
                        </v-col>
                    </v-row>
                    
                    <InterviewHistory 
                        :messages="interview.messages"
                        :started="started"
                    />

                    <InterviewDialog
                        v-model="started"
                        :interview="interview"
                        :user="user"
                        :user-speaking="userSpeaking"
                        :user-speaking-counter="userSpeakingCounter"
                        :response-processing="responseProcessing"
                        :ai-speaking="aiSpeaking"
                        :starting="starting"
                        :last-ai-question="lastAiQuestion"
                        @close="handleCloseInterviewModal"
                        @start-speaking="handleStartSpeaking"
                        @stop-speaking="handleStopSpeaking"
                    />

                    <InterviewResults
                        v-model="finished"
                        :interview="interview"
                        @take-survey="handleTakeTheSurvey"
                    />

                    <NewConfetti v-if="finished" />
                </v-tabs-window-item>
                <v-tabs-window-item value="grades_description">
                    <GradesDescription />
                </v-tabs-window-item>
            </v-tabs-window>
            
        </v-sheet>
        <div :style="{display: 'none'}">
            <audio id="audio" controls ref="audioRef"></audio>
        </div>
        <v-snackbar
            v-model="microphoneError"
            timeout="5000"
            color="error"
            location="top"
        >
            Microphone access is required for the interview. Please grant permission in your browser settings.
        </v-snackbar>
    </v-sheet>
</template>

<script>
import api from '@/utils/api'
import { useRoute } from 'vue-router'
import { ref, onUnmounted } from 'vue'
import { getInterviewTitle, getInterviewTabs, getInterviewDescription } from '@/utils/interviews'
import randomstring from 'randomstring'
import UAParser from 'ua-parser-js'
import { useAppStateStore } from '@/stores/AppStateStore'
import { storeToRefs } from 'pinia'
import { languages } from '@/utils/interviews'
import NewConfetti from '@/components/common/NewConfetti.vue'
import CVUploader from '@/components/cv/CVUploader.vue'
import GradesDescription from '@/components/interview/GradesDescription.vue'
import InterviewDialog from '@/components/interview/InterviewDialog.vue'
import InterviewResults from '@/components/interview/InterviewResults.vue'
import InterviewHistory from '@/components/interview/InterviewHistory.vue'

const browserEngineCodecs = {
    'WebKit': 'audio/mp4',
    'Gecko': 'audio/ogg',
    'Blink': 'audio/webm',
}

const USER_SPEAKING_TIME_LIMIT = 599

export default {
    components: {
        NewConfetti, 
        CVUploader, 
        GradesDescription,
        InterviewDialog,
        InterviewResults,
        InterviewHistory
    },
    setup() {
        const route = useRoute()
        const id = route.params.id

        const currentTab = ref('interview')
        const language = ref(window.localStorage.getItem('interview_language') || 'english')
        const handleUpdateLanguage = () => {
            window.localStorage.setItem('interview_language', language.value)
        }

        const appStateStore = useAppStateStore()
        const { user } = storeToRefs(appStateStore)

        const uaParser = new UAParser()
        const subtype = ref(null)

        const getSubtypes = (type) => {
            switch( type ) {
                case 'design_screening_base':
                    return [
                        {
                            title: 'Junior',
                            value: 'design_screening_junior'
                        },
                        {
                            title: 'Middle',
                            value: 'design_screening_middle'
                        },
                        {
                            title: 'Senior',
                            value: 'design_screening_senior'
                        },
                        {
                            title: 'Lead',
                            value: 'design_screening_lead'
                        }
                    ]
            }
        }

        const browserEngine = ref(uaParser.getResult().engine.name)
        console.log(browserEngine.value)

        const audioRef = ref()
        const interview = ref(null)
        const started = ref(false)
        const starting = ref(false)
        const finished = ref(false)
        let finishedMarker = false

        const lastAiQuestion = ref(null)
        const audioDataStop = ref(false)

        let speechStream = null
        let recorder = null

        const microphoneError = ref(false)

        const initMicro = async () => {
            try {
                speechStream = await navigator.mediaDevices.getUserMedia({audio: true})
                speechStream.getTracks().forEach(track => track.stop())
            } catch (error) {
                if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                    microphoneError.value = true
                }
            }
        }

        initMicro()

        let wsChannelSubscription = null

        const getInterview = async () => {
            const response = await api.get(`/api/interview/get/${id}`)

            if(response.interview) {
                interview.value = response.interview
                subtype.value = interview.value.type === 'design_screening_base' ? 'design_screening_middle' : null
            }
        }

        getInterview()

        const userSpeaking = ref(false)
        let userSpeakingTimer = null
        const userSpeakingCounter = ref(null)
        const responseProcessing = ref(false)
        const aiSpeaking = ref(false)

        let audioContext = null

        const startSpeechStream = async () => {
            if(userSpeaking.value) {
                return
            }
            try {
                userSpeaking.value = true
                userSpeakingCounter.value = USER_SPEAKING_TIME_LIMIT
                userSpeakingTimer = setInterval(() => {
                    userSpeakingCounter.value = userSpeakingCounter.value - 1
                    if(userSpeakingCounter.value <= 0) {
                        handleStopSpeaking()
                        userSpeakingCounter.value = null
                        clearInterval(userSpeakingTimer)
                    }
                }, 1000)
                const sess = randomstring.generate(10)
                speechStream = await navigator.mediaDevices.getUserMedia({audio: true})
                audioDataStop.value = false
                recorder = new MediaRecorder(speechStream, {
                    mimeType: browserEngineCodecs[browserEngine.value] || 'audio/webm'
                })
                recorder.start(2000)

                recorder.addEventListener('dataavailable', event => {
                    const timestamp = new Date().getTime()
                    const response = fetch('/api/interview/audio-chunk?'+ new URLSearchParams({sess: sess, ts: timestamp}), {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/octet-stream'
                        },
                        body: event.data
                    })
                })

                recorder.addEventListener('stop', () => {
                    if(audioDataStop.value) {
                        return
                    }
                    audioDataStop.value = true
                    setTimeout(() => {
                        processUserAudio(sess)
                    }, 300)
                })
            } catch (error) {
                if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                    microphoneError.value = true
                }
                userSpeaking.value = false
                userSpeakingCounter.value = null
                clearInterval(userSpeakingTimer)
            }
        }

        const stopSpeechStream = async () => {
            if(speechStream) {
                speechStream.getTracks().forEach(track => track.stop())
            }
            if(recorder) {
                recorder.stop()
            }
            userSpeaking.value = false
            userSpeakingCounter.value = null
            clearInterval(userSpeakingTimer)
        }

        const handleStartSpeaking = (event) => {
            if(!started.value) {
                return
            }
            if(userSpeaking.value) {
                return
            }
            if(interview.value.status == 2) {
                return
            }
            if(responseProcessing.value == false && aiSpeaking.value == false) {
                startSpeechStream()
            }
        }

        const handleStopSpeaking = (event) => {
            if(!started.value) {
                return
            }
            if(!userSpeaking.value) {
                return
            }
            stopSpeechStream()
        }

        onUnmounted(() => {
            if(speechStream) {
                speechStream.getTracks().forEach(track => track.stop())
            }
            if(recorder) {
                recorder.stop()
            }

            if(wsChannelSubscription) {
                wsChannelSubscription.unsubscribe()
                document.wsClient.removeSubscription(wsChannelSubscription)
                wsChannelSubscription = null
            }
        })

        const processUserAudio = async (sess) => {
            responseProcessing.value = true
            let response = await fetch(`/api/interview/finish-audio/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    sess: sess,
                    codec: browserEngineCodecs[browserEngine.value] ?? null
                })
            })
            handleAudioResponse(response)
        }

        const handleStartInterview = async () => {
            audioContext = new (window.AudioContext)()
            responseProcessing.value = true
            started.value = true
            starting.value = true
            let useCv = false
            if (interview.value.type == 'design_screening' && user.value.features['feature_interview_cv_based']) {
                useCv = true
            }
            if (interview.value.type == 'design_screening_base' && !subtype.value) {
                return;
            }
            let response = await fetch(`/api/interview/start-interview/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    codec: browserEngineCodecs[browserEngine.value] ?? null,
                    language: language.value,
                    use_cv: useCv,
                    subtype: subtype.value
                })
            })
            starting.value = false
            handleAudioResponse(response)
        }

        const handleContinueInterview = async () => {
            audioContext = new (window.AudioContext)()
            responseProcessing.value = true
            started.value = true
            starting.value = true
            let response = await fetch(`/api/interview/continue-interview/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    codec: browserEngineCodecs[browserEngine.value] ?? null,
                })
            })
            starting.value = false
            handleAudioResponse(response)
        }

        const handleAudioResponse = async (response) => {
            if(response.status != 200) {
                responseProcessing.value = false
                return
            }

            const audioResponseBlob = await response.blob()
            responseProcessing.value = false
            aiSpeaking.value = true
            audioRef.value.src = URL.createObjectURL(audioResponseBlob)
            if(started.value) {
                audioRef.value.play()
            } else {
                aiSpeaking.value = false
            }

            audioRef.value.addEventListener('ended', () => {
                aiSpeaking.value = false
                if(finishedMarker) {
                    started.value = false
                    finished.value = true
                }
            }, { once: true })
        }

        const handleCloseInterviewModal = () => {
            started.value = false
            starting.value = false
            audioRef.value.pause()
            audioRef.value.currentTime = 0
            lastAiQuestion.value = null
            aiSpeaking.value = false
        }

        const handleTakeTheSurvey = () => {
            window.open('https://forms.gle/by5YK8DphvR5x7ZbA', '_blank')
        }

        //Connect websokect interview channel
        if(document.wsClient) {
            wsChannelSubscription = document.wsClient.newSubscription('channel_interview_'+id)
            wsChannelSubscription.on('publication', (ctx) => {
                switch (ctx.data.type) {
                    case 'interview_updated':
                        interview.value.messages = ctx.data.payload.messages
                        if(interview.value.status != ctx.data.payload.status && ctx.data.payload.status == 2) {
                            interview.value.status = ctx.data.payload.status
                            interview.value.score = ctx.data.payload.score
                            interview.value.score_grade = ctx.data.payload.score_grade
                            interview.value.score_data = ctx.data.payload.score_data
                            finishedMarker = true
                        }
                        break
                    case 'last_question_updated':
                        lastAiQuestion.value = ctx.data.payload.text
                    default:
                        break
                }
            }).subscribe()
        }

        return {
            interview,
            getInterviewTitle,
            started,
            userSpeaking,
            userSpeakingCounter,
            responseProcessing,
            aiSpeaking,
            handleStartInterview,
            handleContinueInterview,
            audioRef,
            browserEngine,
            user,
            handleStartSpeaking,
            handleStopSpeaking,
            handleCloseInterviewModal,
            finished,
            handleUpdateLanguage,
            language,
            languages,
            starting,
            lastAiQuestion,
            getInterviewTabs,
            getInterviewDescription,
            currentTab,
            subtype,
            getSubtypes,
            handleTakeTheSurvey,
            microphoneError
        }
    }
}
</script>

<style>
.mt-100p {
    margin-top: 100px;
}
.height180 {
    height: 180px;
}

.height267 {
    height: 267px;
}
</style>