<template>
    <v-dialog
        :model-value="modelValue"
        @update:model-value="$emit('update:modelValue', $event)"
        max-width="1100px"
        persistent
        transition="fade-transition"
    >
        <v-card :class="modalClasses" rounded="xl" :style="{minHeight: '653px'}">
            <v-row class="ma-0">
                <v-col cols="12" class="text-center">
                    <span class="text-center" :class="{'exie-text-h5': !xs, 'exie-text-subtitle-1': xs, 'font-weight-bold': xs}">{{ getInterviewTitle(interview.type) }} interview</span>
                    <v-btn color="black" class="float-end" density="compact" :icon="mdiClose" variant="flat" @click="$emit('close')"></v-btn>
                </v-col>
            </v-row>
            <v-sheet :class="modalClasses" class="d-flex align-center justify-center" :style="{minHeight: '600px'}">
                <v-row class="ma-0 px-xs-2 px-md-6 px-lg-12" v-if="starting">
                    <v-col cols="12" class="d-flex justify-center">
                        <div class="d-flex flex-column align-center justify-center" :style="{maxWidth: '500px'}">
                            <v-progress-circular indeterminate size="43" color="black"></v-progress-circular>
                            <p class="exie-text-h6 text-center mt-3">
                                Sit back and get ready for growth
                            </p>
                        </div>
                    </v-col>
                </v-row>
                <v-row v-else class="ma-0 px-xs-2 px-md-6 px-lg-12">
                    <v-col cols="12" sm="6">
                        <div class="bg-white d-flex flex-column align-center justify-space-between rounded-xl" :class="{'border-lg': userSpeaking, 'border-black': userSpeaking, 'border-opacity-100': userSpeaking, 'height180': xs, 'height267': !xs}">
                            <p :style="{height: '36px', width: '100%'}" class="exie-text-h5 mb-2"><span v-if="userSpeaking" class="float-left ml-7 mt-5" >{{ Math.floor(userSpeakingCounter / 60).toString().padStart(2, '0') }}:{{ (userSpeakingCounter % 60).toString().padStart(2, '0') }} </span></p>
                            <div>
                                <v-avatar :image="user.photo_url" :size="xs ? 48 :120"></v-avatar>
                            </div>
                            <p class="w-100 text-left pl-7 pb-5">{{ user.name }} 
                                <span v-if="userSpeaking" class="float-end mr-7"><ButtonMicrophoneWaves/></span>
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <div class="bg-white d-flex flex-column align-center justify-space-between rounded-xl" :class="{'border-lg': aiSpeaking || responseProcessing, 'border-black': aiSpeaking || responseProcessing, 'border-opacity-100': aiSpeaking || responseProcessing, 'height180': xs, 'height267': !xs}">
                            <p style="height: 36px;"></p>
                            <div>
                                <v-avatar image="/images/mockin_avatar.svg" :size="xs ? 48: 120"></v-avatar>
                            </div>
                            <p class="w-100 text-left pl-7 pb-5">Mockin HR 
                                <span v-if="aiSpeaking" class="float-end mr-7"><VoiceAnimation /></span>
                                <span v-if="responseProcessing" class="float-end mr-7"><v-progress-circular v-if="responseProcessing" indeterminate size="20" color="black"></v-progress-circular></span>
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center align-center mt-4">
                        <div class="d-flex justify-center align-center">
                            <span class="text-center" :class="{'exie-text-h5': !xs, 'exie-text-body-2': xs, 'text-black': xs}">{{ lastAiQuestion }}</span>
                        </div>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <div class="mt-4">
                            <v-btn v-if="!userSpeaking && !aiSpeaking && !responseProcessing" @click="$emit('start-speaking')" size="large" color="black" variant="flat" class="rounded-16" :class="{'w-100': xs}">Start speaking</v-btn>
                            <v-btn v-if="aiSpeaking || responseProcessing" disabled size="large" color="black" variant="flat" class="rounded-16" :class="{'w-100': xs}">Start speaking</v-btn>
                            <v-btn v-if="userSpeaking" @click="$emit('stop-speaking')" color="pink-darken-3" size="large" variant="flat" class="rounded-16" :class="{'w-100': xs}">Stop speaking</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref, watch } from 'vue'
import { mdiClose } from '@mdi/js'
import { useDisplay } from 'vuetify'
import ButtonMicrophoneWaves from './ButtonMicrophoneWaves.vue'
import VoiceAnimation from './VoiceAnimation.vue'
import { getInterviewTitle, getModalBgClass } from '@/utils/interviews'

const props = defineProps({
    modelValue: Boolean,
    interview: Object,
    user: Object,
    userSpeaking: Boolean,
    userSpeakingCounter: Number,
    responseProcessing: Boolean,
    aiSpeaking: Boolean,
    starting: Boolean,
    lastAiQuestion: String
})

defineEmits(['update:modelValue', 'close', 'start-speaking', 'stop-speaking'])

const { xs } = useDisplay()
const modalClasses = ref({})

watch(() => props.interview, (newVal) => {
    if (newVal) {
        modalClasses.value[getModalBgClass(newVal.type)] = true
    }
}, { immediate: true })
</script>

<style>
.height180 {
    height: 180px;
}

.height267 {
    height: 267px;
}
</style> 