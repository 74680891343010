const interviews = [
    {
        title: 'Backend Developer',
        value: 'backend_developer',
        show: true,
        legacy: true
    },
    {
        title: 'Frontend Developer',
        value: 'frontend_developer',
        show: true,
        legacy: true
    },
    {
        title: 'Product Analyst',
        value: 'product_analyst',
        show: true,
        legacy: true
    },
    {
        title: 'Data Scientist',
        value: 'data_scientist',
        show: true,
        legacy: true
    },
    {
        title: 'QA Engineer',
        value: 'qa_engineer',
        show: true,
        legacy: true
    },
    {
        title: 'Project Manager',
        value: 'project_manager',
        show: true,
        legacy: true
    },
    {
        title: 'Product Manager',
        value: 'product_manager',
        show: true,
        legacy: true
    },
    {
        title: 'Product Designer',
        value: 'product_designer',
        show: true,
        legacy: true
    },
    {
        title: 'Product Designer (Test)',
        value: 'product_designer_test',
        test: true,
        show: true,
        legacy: true
    },
    {
        title: 'Marketing Manager (Test)',
        value: 'marketing_manager_test',
        test: true,
        legacy: true
    },
    {
        title: 'Product Marketing Manager (Test)',
        value: 'product_marketing_manager_test',
        test: true,
        legacy: true
    },
    {
        title: 'Marketing Manager',
        value: 'marketing_manager',
        show: true,
        legacy: true
    },
    {
        title: 'Product Marketing Manager',
        value: 'product_marketing_manager',
        show: true,
        legacy: true
    },
    {
        title: 'Screening',
        value: 'design_screening',
        tabs: [
            {
                id: 'interview',
                title: 'Interview Settings'
            }
        ],
        modal_bg_class: 'bg-indigo-lighten-4',
        description: "The interview is divided into several parts and should take about 20-30 minutes, depending on your responses.\n\nExpect a conversational style — you can ask the interviewer to repeat or rephrase questions, and you're welcome to take short breaks as needed."
    },
    {
        title: 'Screening',
        value: 'design_screening_base',
        tabs: [
            {
                id: 'interview',
                title: 'Interview Settings'
            }
        ],
        modal_bg_class: 'bg-indigo-lighten-4',
        description: "The interview is divided into several parts and should take about 20-30 minutes, depending on your responses.\n\nExpect a conversational style — you can ask the interviewer to repeat or rephrase questions, and you're welcome to take short breaks as needed."
    },
    {
        title: 'Assessment',
        value: 'design_self_assessment',
        tabs: [
            {
                id: 'interview',
                title: 'Interview Settings'
            },
            {
                id: 'grades_description',
                title: 'Grades Description'
            }
        ],
        modal_bg_class: 'bg-deep-purple-lighten-4',
        description: 'Assessment interview will evaluate your experience and strengths based on the following criteria: Responsibility, Impact, Problem Finding, Problem Solving, Leadership. You will receive detailed feedback on each area and improvement recommendations, helping you better understand which positions to consider in your job search.\n\nThe interview is divided into several parts and should take about 15⁠-⁠25 minutes, depending on your responses.\n\nExpect a conversational style — you can ask the interviewer to repeat or rephrase questions, and you\'re welcome to take short breaks as needed.'
    },
]

const languages = [
    { title: 'English', value: 'english'},
    { title: 'Español', value: 'spanish'},
    { title: 'Português', value: 'portuguese'},
    { title: 'Français', value: 'french'},
    { title: 'Italiano', value: 'italian'},
    { title: 'Deutsch', value: 'german'},
    { title: 'Русский', value: 'russian'},
]

const getInterviewTitle = (value) => {
    const interview = interviews.find(interview => interview.value === value)
    return interview.title
}

const getInterviewTabs = (value) => {
    const interview = interviews.find(interview => interview.value === value)
    return interview.tabs ?? []
}

const getLanguageTitle = (value) => {
    const language = languages.find(language => language.value === value)
    if(language) {
        return language.title
    }
    return ''
}

const getModalBgClass = (value) => {
    const interview = interviews.find(interview => interview.value === value)
    return interview.modal_bg_class ?? 'bg-indigo-lighten-4'
}

const getInterviewDescription = (value) => {
    const interview = interviews.find(interview => interview.value === value)
    return interview.description
}

export { interviews, getInterviewTitle, languages, getLanguageTitle, getInterviewTabs, getModalBgClass, getInterviewDescription }